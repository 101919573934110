import { GenericFormError } from '../../../utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
} from '../../../../lib/hooks/ServerStateHooks';
import { Localized } from '../../../hooks/LanguageProvider';
import { ErrorAlert } from '../ErrorAlert';

export function GenericFormSubmitError({
    error,
    submitState,
}: {
    error: GenericFormError | null;
    submitState: ServerRequestState<any, any>;
}) {
    if (!error) {
        return null;
    }

    const alertSpacing = {
        marginY: 2,
    };

    if (error.isGenericServerError) {
        return (
            <ErrorAlert sx={alertSpacing}>
                <>
                    <Localized
                        de="The request returned an error: status "
                        fr="The request returned an error: status "
                        it="The request returned an error: status "
                        en="The request returned an error: status "
                    />
                    {submitState?.status === RequestStatus.ERROR &&
                        submitState.httpStatusCode}
                </>
            </ErrorAlert>
        );
    }

    return (
        <>
            {error.issues.map((e, i) => (
                <ErrorAlert key={i} sx={alertSpacing}>
                    <Localized {...e.issue.humanReadableMessage.byLanguage} />
                </ErrorAlert>
            ))}
        </>
    );
}
